<template>
  <div class="news__items">
    <news-card v-for="item in items" :key="item.id" :item="item" />
  </div>
</template>

<script>
export default {
  name: "NewsList",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  components: {
    NewsCard: () => import("@/components/news/NewsCard.vue"),
  },
};
</script>
